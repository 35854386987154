import React from 'react';

import './loader.scss';

import useDevice from '../../hooks/useDevice';

import { ReactComponent as Logo } from '../intro/media/logo.svg';
import { ReactComponent as LogoLetter } from '../intro/media/logo-letter.svg';

const Loader = () => {
    const { isMobile } = useDevice();

    return (
        <div className='splash-loader-holder'>
            {isMobile ? (
                <LogoLetter/>
            ) : (
                <Logo/>
            )}
        </div>
    )
};

export default Loader;
