import { useEffect, useState } from 'react';

const useDevice = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    function checkForDevice() {
        setIsMobile(document.documentElement.clientWidth < 667);
        setIsTablet(document.documentElement.clientWidth < 1023);
    }

    useEffect(() => {
        checkForDevice();

        window.addEventListener('resize', checkForDevice);

        return () => window.removeEventListener('resize', checkForDevice);
    }, []);

    return { isMobile, isTablet};
};

export default useDevice;
