export const toggleClass = (state, className, element = document.documentElement) => {
    state ? element.classList.add(className) : element.classList.remove(className);
};

export const currentYear = new Date().getFullYear();

export const scrollTo = element => {
    let top = 0;
    if(element) {
        const headerHeight = getComputedStyle(document.documentElement).getPropertyValue('--header-sticky-height')?.split('rem')[0];
        top = element.offsetTop - (parseFloat(headerHeight) * 10);
    }
    setTimeout(() => {
        document.documentElement.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, 200);
};

export const breakpointAdder = (string, regEx) => {
    const regExp = new RegExp(regEx, 'gi');
    const originalString = string.split(' ');
    const newString = [];

    originalString.forEach(word => {
        const matching = word.match(regExp)?.length;
        newString.push(`${word}${matching ? '<br/>' : ''}`);
    });

    return newString.join(' ');
};

export const camelCase = str => {
    let arr = str.split('-');
    let capital = arr.map((item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item);
    return capital.join("");
}

export const getFeaturedMedia = obj => {
    if(obj && obj?._embedded?.hasOwnProperty('wp:featuredmedia')) {
        return obj._embedded['wp:featuredmedia'][0].source_url;
    }
    return '';
}

export const getFeaturedMediaSizes = obj => {
    if(obj && obj?._embedded?.hasOwnProperty('wp:featuredmedia')) {
        return obj._embedded['wp:featuredmedia'][0].media_details.sizes;
    }
    return null;
}

export const getMediaThumbnail = obj => {
    return obj && getFeaturedMediaSizes(obj)?.medium_large?.source_url;
};

export const getMediaThumbnailSizes = obj => {
    return obj && ({
        width: getFeaturedMediaSizes(obj)?.medium_large.width,
        height: getFeaturedMediaSizes(obj)?.medium_large.height,
    });
};

export const getMediaFull = obj => {
    return obj && getFeaturedMediaSizes(obj)?.full?.source_url;
};

export const getMediaFullSize = obj => {
    return obj && obj?.full?.source_url;
}

export const cleanUrl = (url, asSub) => {
    if (url) {
        const urlSplit = url.split('/');
        const structuredUrl = [];
        urlSplit.slice(3).forEach((str, index) => {
            str && structuredUrl.push(!index ? `/${str === 'home' ? '' : str}` : `${asSub ? '/' : '#'}${str}`)
        });
        return `${structuredUrl.join('')}`;
    }
    return '';
}

export const groupContent = (groups, checker, child, pushable, page, index, tmpData) => {
    const group = groups.find(i => checker(i));
    if(group) {
        group.children.push(child);
    } else {
        groups.push(pushable);
    }
    if(!page.slice(index + 1).some(i => checker(i))) {
        tmpData.push(group);
    }
}

export const renderMenu = (appRoutes, menus) => appRoutes.filter(o1 => menus?.top.some(o2 => o1.id === o2.id) && o1);

const hash = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const guid = () => hash() + hash() + '-' + hash() + '-' + hash() + '-' + hash() + '-' + hash() + hash() + hash();


export const callErr = response => {
    console.error(response);
};

export const rem = num => {
    return `${num / 10}rem`;
}

export const fake = count => {
    return Array(count).fill('');
}

export const fetchURL = url => {
    return process.env.REACT_APP_WEBSITE_WP_ENDPOINT + (url === 'routes' ? process.env.REACT_APP_WEBSITE_ROUTES : `/${url}`);
};
