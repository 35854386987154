import { useEffect, useState } from 'react';

const useWindowResize = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const resize = () => {
            setScreenHeight(window.innerHeight);
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, []);

    return {screenWidth, screenHeight};
};

export default useWindowResize;
