import React, { Suspense, useEffect, useRef, useState, lazy, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';

import SharedContext from './sharedContext';
import Loader from './components/loader';

import useFetch from './hooks/useFetch';
import useWindowResize from './hooks/useWindowResize';

import { modeConfig } from './config';

const Header = lazy(() => import('./components/header').then());
const Footer = lazy(() => import('./components/footer').then());
const Content = lazy(() => import('./components/content').then());
const NotFound = lazy(() => import('./components/notFound').then());

function App() {
    const location = useLocation();
    const doubleNavRef = useRef(null);

    const appRoutes = useFetch('routes');
    const coreSettings = useFetch('core-settings');
    const menus = useFetch('menu');
    const contactForm = useFetch('contacts');
    const [currentPage, setCurrentPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [scrollToSection, setScrollToSection] = useState(null);
    const [mode, setMode] = useState(modeConfig[0]);
    const { screenHeight } = useWindowResize();

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }, []);

    useEffect(() => {
        const pathname = location.pathname;
        const currentPath = pathname !== '/' && pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;
        appRoutes && setCurrentPage(appRoutes.find(route => route.url === `/${currentPath === '/' ? '' : currentPath.split('/')[1]}`));
        ReactGA.pageview(location.pathname + location.hash);
    }, [location.pathname, appRoutes]); // eslint-disable-line

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0
        });
    }, [currentPage]);

    const pageFound = currentPage && !!currentPage.children.length;
    const appReady = appRoutes && coreSettings && menus;

    const FallBack = () => {
        useEffect(() => {
            setTimeout(() => {
                setLoading(false);
            }, 150);
        }, []);
        return null;
    }

    const meta = useMemo(() => currentPage && ({
        title: `${location.pathname !== '/' ? `${currentPage?.title ? `${currentPage.title} ${coreSettings?.cs_website_title_separator} ` : ''}` : ''}${coreSettings?.cs_website_title}`,
        location: process.env.REACT_APP_WEBSITE_URL + location.pathname + location.hash,
        name: coreSettings?.cs_website_name || process.env.REACT_APP_NAME,
        ...currentPage.meta,
    }), [location.pathname, location.hash, coreSettings, currentPage]);

    useEffect(() => {
        document.documentElement.style.setProperty('--window-height', screenHeight + 'px');
    }, [screenHeight]);

    return (
        <HelmetProvider>
            <Helmet>
                {meta && (
                    <>
                        <title>{meta.title}</title>
                        <meta content={meta.title} itemProp="name"/>
                        <meta content={meta.title} property="og:title"/>
                        <meta content={meta.title} name="twitter:title"/>
                        <meta content={meta.description} name="description"/>
                        <meta content={meta.description} itemProp="description"/>
                        <meta content={meta.description} property="og:description"/>
                        <meta content={meta.description} name="twitter:description"/>
                        <meta content={meta.keywords} name="keywords"/>
                        <link href={meta.location} rel="alternate" hrefLang="x-default"/>
                        <meta content={meta.location} property="og:url"/>
                        <link href={meta.location} rel="canonical"/>
                        <meta content={meta.name} name="apple-mobile-web-app-title"/>
                        <meta content={meta.name} name="application-name"/>
                        <meta content={meta.name} property="og:site_name"/>
                    </>
                )}
            </Helmet>
            {appReady && (
                <SharedContext.Provider
                    value={{
                        mode,
                        menus,
                        setMode,
                        appRoutes,
                        contactForm,
                        doubleNavRef,
                        coreSettings,
                        scrollToSection,
                        setScrollToSection,
                    }}
                >
                    {pageFound ? (
                        <>
                            <Suspense fallback={<FallBack/>}>
                                <Header/>
                            </Suspense>
                            <main>
                                <Suspense fallback={<FallBack/>}>
                                    <Content page={currentPage.children} />
                                </Suspense>
                            </main>
                            <Suspense fallback={<FallBack/>}>
                                <Footer/>
                            </Suspense>
                        </>
                    ) : (
                        <Suspense fallback={<FallBack/>}>
                            <NotFound/>
                        </Suspense>
                    )}
                </SharedContext.Provider>
            )}
            {loading && (
                <Loader/>
            )}
        </HelmetProvider>
    );
}

export default App;
