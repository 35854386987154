import PropTypes from 'prop-types';

export const buttonConfig = {
    color: [
        'black',
        'white',
    ],
    appearance: [
        'outline',
        'fill',
        'light',
    ],
    type: [
        'NavLink',
        'submit',
    ],
    flexibility: [
        'content',
        'full',
        'fit',
    ],
};

export const modeConfig = [
    'dark',
    'light',
];

export const reviewItemConfig = {
    appearance: [
        'compact',
        'wide',
    ],
};

export const contactFormConfig = {
    appearance: [
        'wide',
        'compact',
    ],
};

export const blockConfig = {
    appearance: [
        'block',
        'story',
        'imageBreak',
        'letter',
        'note',
        'aside',
        'tab',
    ],
};

export const introConfig = {
    appearance: [
        'video',
        'cut',
        'large',
        'outline',
        'cutOutline',
        'smallOutline',
        'map',
    ],
};

export const ctaProps = PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
});

export const quoteProps = PropTypes.shape({
    author: PropTypes.string,
    content: PropTypes.string,
});
