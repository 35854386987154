import { useEffect, useState } from 'react';
import { callErr, cleanUrl, fetchURL, getFeaturedMediaSizes } from '../utils';

const dataFormatter = (url, posts) => {
    if (url === 'routes') {
        const tmpArr = [];
        const subChildren = [];
        posts.forEach(route => {
            const tmp = {
                children: [],
                id: parseFloat(route.id),
                url: cleanUrl(route.link),
                title: route.title.rendered,
                mode: route.appearance_mode,
                type: route.content_type_type,
                content: route.content.rendered,
                subTitle: route.appearance_sub_title,
                appearance: route.appearance_appearance,
                featuredImage: getFeaturedMediaSizes(route),
                cta: {
                    title: route.appearance_cta_cta_label,
                    url: cleanUrl(route.appearance_cta_cta_page?.url),
                },
                quote: {
                    content: route.appearance_quote_quote_text,
                    author: route.appearance_quote_quote_author,
                },
                widget: {
                    type: route.appearance_widget_settings_type,
                    contactFormAppearance: route.appearance_widget_settings_contact_form_appearance,
                    relation: Number(route.appearance_widget_settings_page_overview_settings_page_relation),
                    cta: {
                        prefix: route.appearance_widget_settings_page_overview_settings_cta_prefix,
                    },
                },
                intro: {
                    appearance: route.appearance_intro_style_style,
                    map: {
                        zoom: route.appearance_intro_style_map_settings_zoom,
                        lat: route.appearance_intro_style_map_settings_latitude,
                        lng: route.appearance_intro_style_map_settings_longitude,
                    },
                },
                group: (route.content_grouping_block_group && route.content_grouping_group_type) ? {
                    id: route.content_grouping_block_group,
                    type: route.content_grouping_group_type,
                } : null,
                meta: {
                    keywords: route.keywords,
                    description: route.description,
                }
            };
            if (route.parent) {
                tmp.parent = route.parent;
                subChildren.push(tmp);
            } else {
                tmpArr.push(tmp);
            }
        });
        subChildren.forEach(subRoute => {
            tmpArr.find(route => route.id === subRoute.parent).children.push(subRoute);
        });
        return tmpArr;
    }

    return posts;
};

const useFetch = url => {
    const [data, setData] = useState(null);

    useEffect(() => {
        let canUpdate = true;

        async function loadData() {
            const response = await fetch(fetchURL(url));
            if (canUpdate) {
                if (!response.ok) {
                    callErr(response);
                    return;
                }

                const posts = await response.json();

                setData(dataFormatter(url, posts));
            }
        }

        loadData().then();
        return () => {
            canUpdate = false;
        };

    }, [url]);

    return data;
};

export default useFetch;
